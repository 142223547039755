import { ComponentPropsWithoutRef, MouseEvent, ReactNode } from "react";
import { UrlObject } from "url";
import NextLink from "next/link";
import { ExternalLink24 } from "@bphxd/ds-core-react/lib/icons";

import { trackEvent } from "../utils/event-tracker";
import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const getFocusBorderRadius = ({ hasFocusBorder }: { hasFocusBorder: boolean }) =>
  hasFocusBorder
    ? `
  &:focus-visible {
    border-radius: 16px;
  }
`
    : "";

const A = styled("a", { shouldForwardProp: (prop) => isPropValid(prop) })`
  display: inline-block;
  ${getFocusBorderRadius}
`;

const RouterA = styled(NextLink, {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  display: inline-block;
  ${getFocusBorderRadius}
`;

export function isUrlInternal(href: string) {
  return /^[/?#]/.test(makeUrlInternal(href));
}

function makeUrlInternal(href: string) {
  let target = href;
  if (window?.location?.origin) {
    target = href.replace(window.location.origin, "");
  }

  return target;
}

type LinkProps = Omit<ComponentPropsWithoutRef<"a">, "href"> & {
  href: string | UrlObject | undefined;
  name?: string;
  hideExternalLinkIcon?: boolean;
  doNotTrack?: boolean;
  dataTestid?: string;
  hasFocusBorder?: boolean;
  children: ReactNode;
  trackingEventProps?: Record<string, any>;
};

export function Link({
  href,
  className = "",
  name = "",
  hideExternalLinkIcon = false,
  style = {},
  onClick = () => {},
  doNotTrack = false,
  tabIndex = 0,
  dataTestid = "",
  hasFocusBorder = false,
  children,
  trackingEventProps,
  ...rest
}: LinkProps) {
  if (!href) {
    console.error({
      message: "anchor tag is missing href",
      dataTestid,
      ...rest,
    });
    return null;
  }

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    if (doNotTrack) {
      return onClick(e);
    }
    if (name || trackingEventProps?.name) {
      trackEvent({ name: `link_${name}_click`, ...trackingEventProps });
    }
    onClick(e);
  }

  if (typeof href === "object") {
    return (
      <RouterA
        href={href}
        style={style}
        className={className || undefined}
        data-testid={dataTestid || undefined}
        onClick={handleClick}
        hasFocusBorder={hasFocusBorder}
        {...rest}
      >
        {children}
      </RouterA>
    );
  }

  // Handle internal URLs
  return isUrlInternal(href) ? (
    <RouterA
      href={makeUrlInternal(href)}
      style={style}
      className={className || undefined}
      data-testid={dataTestid || undefined}
      onClick={handleClick}
      hasFocusBorder={hasFocusBorder}
      {...rest}
    >
      {children}
    </RouterA>
  ) : (
    <A
      href={href}
      tabIndex={tabIndex || undefined}
      target="_blank"
      rel="noreferrer"
      style={style}
      data-testid={dataTestid || undefined}
      className={className || undefined}
      onClick={handleClick}
      hasFocusBorder={hasFocusBorder}
      {...rest}
    >
      {hideExternalLinkIcon ? (
        children
      ) : (
        <div className="d-flex align-items-start">
          <div>{children}</div>
          <ExternalLink24 data-testid="external-link-icon" />
        </div>
      )}
    </A>
  );
}
