export const FEATURE_FLAGS = [
  "FE_ASSESSMENT_RELATED_BUG_FIXES_ENABLED",
  "FE_SHOW_EVENTS_ON_HOMEPAGE_ENABLED",
  "AXEL_KH_TAGS_SUGGESTIONS",
  "AXEL_USE_STREAM",
  "FE_HIDE_EMPIRICAL_DATA",
  "FE_SHOW_SERVICES_TAB",
  "FE_SHOW_SERVICES_TAB_2",
] as const;

export type FeatureFlag = (typeof FEATURE_FLAGS)[number];

export function getDefaultFeatureFlags(): Record<FeatureFlag, boolean> {
  return FEATURE_FLAGS.reduce(
    (acc, curr) => {
      acc[curr] = false;
      return acc;
    },
    {} as Record<FeatureFlag, boolean>,
  );
}
